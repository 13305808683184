import { Model } from '@models/data/model';


export class Operations extends Model {
	values: any;

	constructor(data:any) {
		super(data);
		this.values = data.values
	}
}