import { Flow } from '@models/data/flow';
import { ReferenceInput } from '@models/inputs/reference-input';

export class FlowInput {
	content?: string;
	description?: string;
	manageManager?: ReferenceInput;
	name?: string;
	possedeInfrastructure?: ReferenceInput;

	constructor(flow: Flow) {
		this.content = flow.content
		this.description = flow.description
		this.manageManager = ReferenceInput.createIfValid(flow.manageManager);
		this.name = flow.name
		this.possedeInfrastructure = ReferenceInput.createIfValid(flow.possedeInfrastructure);
	}
}