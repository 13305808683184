import { Alerte } from '@models/data/alerte';
import { ADAPTERS } from '@models/adapters/adapter-resolver';
import { ReferenceInput } from '@models/inputs/reference-input';

export class AlerteInput {
	aquite?: boolean;
	date?: string;
	description?: string;
	type?: ReferenceInput;

	constructor(alerte: Alerte) {
		this.aquite = alerte.aquite
		this.date = ADAPTERS['DateModel'].serializeIfPresent(alerte.date) as string;
		this.description = alerte.description
		this.type = ReferenceInput.createIfValid(alerte.type);
	}
}