import { Category } from '@models/data/category';
import { ReferenceInput } from '@models/inputs/reference-input';

export class CategoryInput {
	code?: string;
	name?: string;
	parentCategory?: ReferenceInput;
	produits?: ReferenceInput[];
	subCategories?: ReferenceInput[];

	constructor(category: Category) {
		this.code = category.code
		this.name = category.name
		this.parentCategory = ReferenceInput.createIfValid(category.parentCategory);
		this.produits = ReferenceInput.createAllIfValid(category.produits);
		this.subCategories = ReferenceInput.createAllIfValid(category.subCategories);
	}
}