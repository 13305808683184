import { Commande } from '@models/data/commande';
import { Model } from '@models/data/model';
import { tryInstantiateAll } from '@shared/utils/object.utils';

export class StatutCommande extends Model {
	code: string;
	commandes: Commande[];
	name: string;

	constructor(data:any) {
		super(data);
		this.code = data.code;
		this.commandes = tryInstantiateAll(data.commandes)
		this.name = data.name;
	}
}