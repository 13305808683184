import { ADAPTERS } from '@models/adapters/adapter-resolver';
import { DateModel } from '@models/adapters/date-adapter';
import { Category } from '@models/data/category';
import { Model } from '@models/data/model';
import { Origin } from '@models/enums/origin';
import { tryInstantiate } from '@shared/utils/object.utils';

export class Produit extends Model {
	barcode: string;
	category: Category;
	code2: string;
	code3: string;
	code: string;
	lastUpdate: DateModel;
	name2: string;
	name3: string;
	name: string;
	origin: Origin;
	packagingQty: number;
	price: number;
	unit: string;
	vaT?: number;
	validFormat: boolean;

	constructor(data:any) {
		super(data);
		this.barcode = data.barcode;
		this.category = tryInstantiate(data.category);
		this.code = data.code;
		this.code2 = data.code2;
		this.code3 = data.code3;
		this.lastUpdate = ADAPTERS['DateModel'].parseIfPresent(data.lastUpdate) as DateModel;
		this.name = data.name;
		this.name2 = data.name2;
		this.name3 = data.name3;
		this.origin = data.origin;
		this.packagingQty = data.packagingQty;
		this.price = data.price;
		this.unit = data.unit;
		this.vaT = data.vaT;
		this.validFormat = data.validFormat;
	}
}