import { Model } from '@models/data/model';
import { Produit } from '@models/data/produit';
import { tryInstantiate } from '@shared/utils/object.utils';
import { tryInstantiateAll } from '@shared/utils/object.utils';

export class Category extends Model {
	code: string;
	name: string;
	parentCategory: Category;
	produits: Produit[];
	subCategories: Category[];

	constructor(data:any) {
		super(data);
		this.code = data.code;
		this.name = data.name;
		this.parentCategory = tryInstantiate(data.parentCategory);
		this.produits = tryInstantiateAll(data.produits)
		this.subCategories = tryInstantiateAll(data.subCategories)
	}
}