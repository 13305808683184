import { Model } from '@models/data/model';
import { Organization } from '@models/data/organization';
import { Role } from '@models/data/role';
import { UsersGroup } from '@models/data/users-group';
import { tryInstantiate } from '@shared/utils/object.utils';
import { tryInstantiateAll } from '@shared/utils/object.utils';
import { UserProperties } from '@models/enums/user-properties';
import { Session } from '@models/data/session';

export class User extends Model {
	accountExpired: boolean;
	accountLocked: boolean;
	appartientOrganization: Organization;
	appartientUsersGroups: UsersGroup[];
	credentialsExpired: boolean;
	email: string;
	enabled: boolean;
	firstName: string;
	isFirstConnection: boolean;
	lastName: string;
	password: string;
	possedeRoles: Role[];
	userName: string;

	sessions: Session[];
	properties: Record<UserProperties, string | null>;

	constructor(data:any) {
		super(data);
		this.accountExpired = data.accountExpired;
		this.accountLocked = data.accountLocked;
		this.appartientOrganization = tryInstantiate(data.appartientOrganization);
		this.appartientUsersGroups = tryInstantiateAll(data.appartientUsersGroups)
		this.credentialsExpired = data.credentialsExpired;
		this.email = data.email;
		this.enabled = data.enabled;
		this.firstName = data.firstName;
		this.isFirstConnection = data.isFirstConnection;
		this.lastName = data.lastName;
		this.password = data.password;
		this.possedeRoles = tryInstantiateAll(data.possedeRoles)
		this.userName = data.userName;

		this.sessions = data.sessions ?? [];
		this.properties = data.properties;
	}
}