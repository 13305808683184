import { Site } from '@models/data/site';
import { ReferenceInput } from '@models/inputs/reference-input';

export class SiteInput {
	description?: string;
	name?: string;
	possedeInfrastructure?: ReferenceInput;
	possedeManagers?: ReferenceInput[];
	serviceHospitaliers?: ReferenceInput[];

	constructor(site: Site) {
		this.description = site.description
		this.name = site.name
		this.possedeInfrastructure = ReferenceInput.createIfValid(site.possedeInfrastructure);
		this.possedeManagers = ReferenceInput.createAllIfValid(site.possedeManagers);
		this.serviceHospitaliers = ReferenceInput.createAllIfValid(site.serviceHospitaliers);
	}
}