import { Model } from '@models/data/model';


export class ObjectReference extends Model {
	objectName: string;

	constructor(data:any) {
		super(data);
		this.objectName = data.objectName;
	}
}