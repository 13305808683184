import { ServiceHospitalier } from '@models/data/service-hospitalier';
import { ReferenceInput } from '@models/inputs/reference-input';

export class ServiceHospitalierInput {
	appartientACommandes?: ReferenceInput[];
	code?: string;
	name?: string;
	orderFrequency?: string;
	orderFriday?: boolean;
	orderMonday?: boolean;
	orderSaturday?: boolean;
	orderSunday?: boolean;
	orderThursday?: boolean;
	orderTuesday?: boolean;
	orderWednesday?: boolean;
	possedeEmplacements?: ReferenceInput[];
	site?: ReferenceInput;

	constructor(serviceHospitalier: ServiceHospitalier) {
		this.appartientACommandes = ReferenceInput.createAllIfValid(serviceHospitalier.appartientACommandes);
		this.code = serviceHospitalier.code
		this.name = serviceHospitalier.name
		this.orderFrequency = serviceHospitalier.orderFrequency
		this.orderFriday = serviceHospitalier.orderFriday
		this.orderMonday = serviceHospitalier.orderMonday
		this.orderSaturday = serviceHospitalier.orderSaturday
		this.orderSunday = serviceHospitalier.orderSunday
		this.orderThursday = serviceHospitalier.orderThursday
		this.orderTuesday = serviceHospitalier.orderTuesday
		this.orderWednesday = serviceHospitalier.orderWednesday
		this.possedeEmplacements = ReferenceInput.createAllIfValid(serviceHospitalier.possedeEmplacements);
		this.site = ReferenceInput.createIfValid(serviceHospitalier.site);
	}
}