import { Model } from '@models/data/model';
import { Station } from '@models/data/station';
import { tryInstantiateAll } from '@shared/utils/object.utils';

export class BusinessStation extends Model {
	lieeStations: Station[];

	constructor(data:any) {
		super(data);
		this.lieeStations = tryInstantiateAll(data.lieeStations)
	}
}