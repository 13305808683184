import { Driver } from '@models/data/driver';
import { Model } from '@models/data/model';
import { ReachableSchema } from '@models/data/reachable-schema';
import { Station } from '@models/data/station';
import { tryInstantiate } from '@shared/utils/object.utils';
import { tryInstantiateAll } from '@shared/utils/object.utils';

export class Implementation extends Model {
	defaultConfiguration: string;
	defaultWorkflow: string;
	name: string;
	possedeDriver: Driver;
	possedeReachableSchemas: ReachableSchema[];
	possedeStations: Station[];
	schema: string;

	constructor(data:any) {
		super(data);
		this.defaultConfiguration = data.defaultConfiguration;
		this.defaultWorkflow = data.defaultWorkflow;
		this.name = data.name;
		this.possedeDriver = tryInstantiate(data.possedeDriver);
		this.possedeReachableSchemas = tryInstantiateAll(data.possedeReachableSchemas)
		this.possedeStations = tryInstantiateAll(data.possedeStations)
		this.schema = data.schema;
	}
}