import { Model } from '@models/data/model';
import { Organization } from '@models/data/organization';
import { tryInstantiate } from '@shared/utils/object.utils';

export class OrganizationEntity extends Model {
	appartientOrganization: Organization;
	entityId: number;
	entityName: string;

	constructor(data:any) {
		super(data);
		this.appartientOrganization = tryInstantiate(data.appartientOrganization);
		this.entityId = data.entityId;
		this.entityName = data.entityName;
	}
}