import { UsersGroup } from '@models/data/users-group';
import { ReferenceInput } from '@models/inputs/reference-input';

export class UsersGroupInput {
	appartientUsers?: ReferenceInput[];
	name?: string;

	constructor(usersGroup: UsersGroup) {
		this.appartientUsers = ReferenceInput.createAllIfValid(usersGroup.appartientUsers);
		this.name = usersGroup.name
	}
}