import { Alerte } from '@models/data/alerte';
import { Model } from '@models/data/model';
import { tryInstantiateAll } from '@shared/utils/object.utils';

export class TypeAlerte extends Model {
	alertes: Alerte[];
	nom: string;

	constructor(data:any) {
		super(data);
		this.alertes = tryInstantiateAll(data.alertes)
		this.nom = data.nom;
	}
}