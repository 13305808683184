import { Tag } from '@models/data/tag';
import { ADAPTERS } from '@models/adapters/adapter-resolver';
import { ReferenceInput } from '@models/inputs/reference-input';

export class TagInput {
	lastDeviceId?: string;
	manageAsset?: ReferenceInput;
	pairingStartTime?: string;

	constructor(tag: Tag) {
		this.lastDeviceId = tag.lastDeviceId
		this.manageAsset = ReferenceInput.createIfValid(tag.manageAsset);
		this.pairingStartTime = ADAPTERS['DateModel'].serializeIfPresent(tag.pairingStartTime) as string;
	}
}