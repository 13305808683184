import { Log } from '@models/data/log';
import { ADAPTERS } from '@models/adapters/adapter-resolver';
import { ReferenceInput } from '@models/inputs/reference-input';

export class LogInput {
	action?: string;
	battery?: string;
	bin?: string;
	categoryCode?: string;
	categoryName?: string;
	column?: string;
	date?: string;
	dropPoint?: string;
	eventType?: ReferenceInput;
	originType?: ReferenceInput;
	possedeServiceHospitalier?: ReferenceInput;
	price?: number;
	productCode?: string;
	productName?: string;
	productOrigin?: string;
	quantity?: number;
	tagId?: number;
	username?: string;

	constructor(log: Log) {
		this.action = log.action
		this.battery = log.battery
		this.bin = log.bin
		this.categoryCode = log.categoryCode
		this.categoryName = log.categoryName
		this.column = log.column
		this.date = ADAPTERS['DateModel'].serializeIfPresent(log.date) as string;
		this.dropPoint = log.dropPoint
		this.eventType = ReferenceInput.createIfValid(log.eventType);
		this.originType = ReferenceInput.createIfValid(log.originType);
		this.possedeServiceHospitalier = ReferenceInput.createIfValid(log.possedeServiceHospitalier);
		this.price = log.price
		this.productCode = log.productCode
		this.productName = log.productName
		this.productOrigin = log.productOrigin
		this.quantity = log.quantity
		this.tagId = log.tagId
		this.username = log.username
	}
}