import { optionalize } from '@shared/utils/object.utils';
import { DateTime } from 'luxon';
import { AbstractAdapter, Adapter } from '@models/adapters/adapter';

class DateAdapter extends AbstractAdapter<DateModel> implements Adapter<DateModel>{

  override parse = (value : string) => {
    let dateModel = fromIsoOptional(value)
    if(dateModel) return dateModel;
    throw new Error("Couldn't parse a date : " + value)
  }

  override serialize = (value : DateModel) => {
    let res = value.toISO();
    if(res) return res;
    throw new Error("Can't serialize the date object");
  }
}

export const dateAdapter = new DateAdapter();

export type DateModel = DateTime;
export const fromIsoOptional = optionalize(DateTime.fromISO);
