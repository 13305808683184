import { Model } from '@models/data/model';
import { User } from '@models/data/user';
import { tryInstantiateAll } from '@shared/utils/object.utils';
import { Permission } from '@models/data/permission';

export class Role extends Model {
	name: string;
	permissions: { [objectName: string]: Permission };
	possedeUsers: User[];

	constructor(data:any) {
		super(data);
		this.name = data.name;
		this.permissions = data.permissions
		this.possedeUsers = tryInstantiateAll(data.possedeUsers)
	}
}