import { Organization } from '@models/data/organization';
import { ReferenceInput } from '@models/inputs/reference-input';

export class OrganizationInput {
	appartientOrganizationEntities?: ReferenceInput[];
	appartientUsers?: ReferenceInput[];
	name?: string;

	constructor(organization: Organization) {
		this.appartientOrganizationEntities = ReferenceInput.createAllIfValid(organization.appartientOrganizationEntities);
		this.appartientUsers = ReferenceInput.createAllIfValid(organization.appartientUsers);
		this.name = organization.name
	}
}