import { StatutCommande } from '@models/data/statut-commande';
import { ReferenceInput } from '@models/inputs/reference-input';

export class StatutCommandeInput {
	code?: string;
	commandes?: ReferenceInput[];
	name?: string;

	constructor(statutCommande: StatutCommande) {
		this.code = statutCommande.code
		this.commandes = ReferenceInput.createAllIfValid(statutCommande.commandes);
		this.name = statutCommande.name
	}
}