import { Model } from '@models/data/model';
import { getType } from '@models/data/type-resolver';

function camelize(str: string): string {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  });
}

export const notNull = <TValue>(
  value: TValue,
): value is NonNullable<TValue> => {
  return value !== null && value !== undefined;
};
export const optionalize = <
  T extends (...args: any[]) => any,
  R extends ReturnType<T> | undefined,
>(
  func: T,
  defaultValue?: R,
) => {
  return (
    // @ts-expect-error : Compiler wrongly assume that Partial<Parameters<T>> can return something that is not an array.
    ...args: Partial<Parameters<T>>
  ): R extends undefined ? ReturnType<T> | undefined : ReturnType<T> => {
    // @ts-expect-error : Same as above
    return args != null && args[0] != null ? func(...args) : defaultValue;
  };
};

export const instantiate = <T extends {}>(
  constructor: new (data: any) => T,
  args: any,
): T => {
  return new constructor(args);
};

export const tryInstantiate = <T extends {}>(
  args?: Partial<Model> | null,
): T => {
  if (args?.__typename) {
    const constructor = getType(camelize(args.__typename));
    return instantiate(constructor, args);
  }
  return undefined as unknown as T;
};

export const tryInstantiateAll = <T extends {}>(args: any[]): T[] => {
  if (args == null) return args as unknown as T[];
  return args.map((arg) => tryInstantiate<T>(arg));
};

export const tryParse = (data: any) => {
  if (data == null) return data;
  return JSON.parse(data);
};
