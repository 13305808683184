import { Manager } from '@models/data/manager';
import { ReferenceInput } from '@models/inputs/reference-input';

export class ManagerInput {
	description?: string;
	manageFlow?: ReferenceInput;
	manageStations?: ReferenceInput[];
	name?: string;
	possedeSite?: ReferenceInput;

	constructor(manager: Manager) {
		this.description = manager.description
		this.manageFlow = ReferenceInput.createIfValid(manager.manageFlow);
		this.manageStations = ReferenceInput.createAllIfValid(manager.manageStations);
		this.name = manager.name
		this.possedeSite = ReferenceInput.createIfValid(manager.possedeSite);
	}
}