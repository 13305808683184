import { Model } from '@models/data/model';
import { ScheduledTaskType } from '@models/data/scheduled-task-type';
import { tryInstantiate } from '@shared/utils/object.utils';

export class ScheduledTask extends Model {
	cronExpression: string;
	profile: string;
	scheduledTaskType: ScheduledTaskType;
	timeZone: string;

	constructor(data:any) {
		super(data);
		this.cronExpression = data.cronExpression;
		this.profile = data.profile;
		this.scheduledTaskType = tryInstantiate(data.scheduledTaskType);
		this.timeZone = data.timeZone;
	}
}