import { EventType } from '@models/data/event-type';


export class EventTypeInput {
	code?: string;
	name?: string;

	constructor(eventType: EventType) {
		this.code = eventType.code
		this.name = eventType.name
	}
}