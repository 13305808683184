import { ScheduledTaskType } from '@models/data/scheduled-task-type';


export class ScheduledTaskTypeInput {
	code?: string;
	name?: string;

	constructor(scheduledTaskType: ScheduledTaskType) {
		this.code = scheduledTaskType.code
		this.name = scheduledTaskType.name
	}
}