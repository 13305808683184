import { BusinessStation } from '@models/data/business-station';
import { DetectionEvent } from '@models/data/detection-event';
import { Implementation } from '@models/data/implementation';
import { Manager } from '@models/data/manager';
import { Model } from '@models/data/model';
import { tryInstantiate } from '@shared/utils/object.utils';
import { tryInstantiateAll } from '@shared/utils/object.utils';

export class Station extends Model {
	configuration: string;
	description: string;
	lieeBusinessStation: BusinessStation;
	lieeDetectionEvents: DetectionEvent[];
	manageManager: Manager;
	name: string;
	possedeImplementation: Implementation;
	workflow: string;
	status: string;

	constructor(data:any) {
		super(data);
		this.configuration = data.configuration;
		this.description = data.description;
		this.status = data.status;
		this.lieeBusinessStation = tryInstantiate(data.lieeBusinessStation);
		this.lieeDetectionEvents = tryInstantiateAll(data.lieeDetectionEvents)
		this.manageManager = tryInstantiate(data.manageManager);
		this.name = data.name;
		this.possedeImplementation = tryInstantiate(data.possedeImplementation);
		this.workflow = data.workflow;
	}
}