import { Model } from '@models/data/model';
import { OrganizationEntity } from '@models/data/organization-entity';
import { User } from '@models/data/user';
import { tryInstantiateAll } from '@shared/utils/object.utils';

export class Organization extends Model {
	appartientOrganizationEntities: OrganizationEntity[];
	appartientUsers: User[];
	name: string;

	constructor(data:any) {
		super(data);
		this.appartientOrganizationEntities = tryInstantiateAll(data.appartientOrganizationEntities)
		this.appartientUsers = tryInstantiateAll(data.appartientUsers)
		this.name = data.name;
	}
}