import { Implementation } from '@models/data/implementation';
import { Model } from '@models/data/model';
import { tryInstantiateAll } from '@shared/utils/object.utils';

export class Driver extends Model {
	artifactId: string;
	groupId: string;
	possedeImplementations: Implementation[];
	version: string;

	constructor(data:any) {
		super(data);
		this.artifactId = data.artifactId;
		this.groupId = data.groupId;
		this.possedeImplementations = tryInstantiateAll(data.possedeImplementations)
		this.version = data.version;
	}
}