import { Implementation } from '@models/data/implementation';
import { ReferenceInput } from '@models/inputs/reference-input';

export class ImplementationInput {
	defaultConfiguration?: string;
	defaultWorkflow?: string;
	name?: string;
	possedeDriver?: ReferenceInput;
	possedeReachableSchemas?: ReferenceInput[];
	possedeStations?: ReferenceInput[];
	schema?: string;

	constructor(implementation: Implementation) {
		this.defaultConfiguration = implementation.defaultConfiguration
		this.defaultWorkflow = implementation.defaultWorkflow
		this.name = implementation.name
		this.possedeDriver = ReferenceInput.createIfValid(implementation.possedeDriver);
		this.possedeReachableSchemas = ReferenceInput.createAllIfValid(implementation.possedeReachableSchemas);
		this.possedeStations = ReferenceInput.createAllIfValid(implementation.possedeStations);
		this.schema = implementation.schema
	}
}