import { Infrastructure } from '@models/data/infrastructure';
import { Manager } from '@models/data/manager';
import { Model } from '@models/data/model';
import { tryInstantiate } from '@shared/utils/object.utils';

export class Flow extends Model {
	content: string;
	description: string;
	manageManager: Manager;
	name: string;
	possedeInfrastructure: Infrastructure;

	constructor(data:any) {
		super(data);
		this.content = data.content;
		this.description = data.description;
		this.manageManager = tryInstantiate(data.manageManager);
		this.name = data.name;
		this.possedeInfrastructure = tryInstantiate(data.possedeInfrastructure);
	}
}