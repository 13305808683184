import { BusinessStation } from '@models/data/business-station';
import { Model } from '@models/data/model';
import { Station } from '@models/data/station';
import { tryInstantiate } from '@shared/utils/object.utils';

export class DevicePairing extends Model {
	businessStation: BusinessStation;
	deviceId: string;
	station: Station;

	constructor(data:any) {
		super(data);
		this.businessStation = tryInstantiate(data.businessStation);
		this.deviceId = data.deviceId;
		this.station = tryInstantiate(data.station);
	}
}