import { OrganizationEntity } from '@models/data/organization-entity';
import { ReferenceInput } from '@models/inputs/reference-input';

export class OrganizationEntityInput {
	appartientOrganization?: ReferenceInput;
	entityId?: number;
	entityName?: string;

	constructor(organizationEntity: OrganizationEntity) {
		this.appartientOrganization = ReferenceInput.createIfValid(organizationEntity.appartientOrganization);
		this.entityId = organizationEntity.entityId
		this.entityName = organizationEntity.entityName
	}
}