import { Infrastructure } from '@models/data/infrastructure';
import { ReferenceInput } from '@models/inputs/reference-input';

export class InfrastructureInput {
	description?: string;
	name?: string;
	possedeFlows?: ReferenceInput[];
	possedeSites?: ReferenceInput[];

	constructor(infrastructure: Infrastructure) {
		this.description = infrastructure.description
		this.name = infrastructure.name
		this.possedeFlows = ReferenceInput.createAllIfValid(infrastructure.possedeFlows);
		this.possedeSites = ReferenceInput.createAllIfValid(infrastructure.possedeSites);
	}
}