import { Model } from '@models/data/model';
import { ADAPTERS } from '@models/adapters/adapter-resolver';
import { DateModel } from '@models/adapters/date-adapter';

export class StationEvent extends Model {
	detectionTime: DateModel;
	status: string;

	constructor(data:any) {
		super(data);
		this.detectionTime = ADAPTERS['DateModel'].parseIfPresent(data.detectionTime) as DateModel;
		this.status = data.status;
	}
}