import { ReachableSchema } from '@models/data/reachable-schema';
import { ReferenceInput } from '@models/inputs/reference-input';

export class ReachableSchemaInput {
	ids?: ReferenceInput[];
	name?: string;
	possedeImplementation?: ReferenceInput;
	schema?: string;
	type?: string;

	constructor(reachableSchema: ReachableSchema) {
		this.ids = ReferenceInput.createAllIfValid(reachableSchema.ids);
		this.name = reachableSchema.name
		this.possedeImplementation = ReferenceInput.createIfValid(reachableSchema.possedeImplementation);
		this.schema = reachableSchema.schema
		this.type = reachableSchema.type
	}
}