import { DestinationEmailType } from '@models/data/destination-email-type';
import { Model } from '@models/data/model';
import { tryInstantiate } from '@shared/utils/object.utils';

export class DestinationEmail extends Model {
	destinationEmailType: DestinationEmailType;
	email: string;

	constructor(data:any) {
		super(data);
		this.destinationEmailType = tryInstantiate(data.destinationEmailType);
		this.email = data.email;
	}
}