import { LigneCommande } from '@models/data/ligne-commande';
import { Model } from '@models/data/model';
import { ServiceHospitalier } from '@models/data/service-hospitalier';
import { StatutCommande } from '@models/data/statut-commande';
import { ADAPTERS } from '@models/adapters/adapter-resolver';
import { DateModel } from '@models/adapters/date-adapter';
import { tryInstantiate } from '@shared/utils/object.utils';
import { tryInstantiateAll } from '@shared/utils/object.utils';

export class Commande extends Model {
	appartientAServiceHospitalier: ServiceHospitalier;
	closingDate: DateModel;
	creationDate: DateModel;
	numero: string;
	possedeLigneCommandes: LigneCommande[];
	statut: StatutCommande;

	constructor(data:any) {
		super(data);
		this.appartientAServiceHospitalier = tryInstantiate(data.appartientAServiceHospitalier);
		this.closingDate = ADAPTERS['DateModel'].parseIfPresent(data.closingDate) as DateModel;
		this.creationDate = ADAPTERS['DateModel'].parseIfPresent(data.creationDate) as DateModel;
		this.numero = data.numero;
		this.possedeLigneCommandes = tryInstantiateAll(data.possedeLigneCommandes)
		this.statut = tryInstantiate(data.statut);
	}
}