import { Flow } from '@models/data/flow';
import { Model } from '@models/data/model';
import { Site } from '@models/data/site';
import { tryInstantiateAll } from '@shared/utils/object.utils';

export class Infrastructure extends Model {
	description: string;
	name: string;
	possedeFlows: Flow[];
	possedeSites: Site[];

	constructor(data:any) {
		super(data);
		this.description = data.description;
		this.name = data.name;
		this.possedeFlows = tryInstantiateAll(data.possedeFlows)
		this.possedeSites = tryInstantiateAll(data.possedeSites)
	}
}