import { Implementation } from '@models/data/implementation';
import { Station } from '@models/data/station';
import { ReachableSchema } from '@models/data/reachable-schema';
import { Driver } from '@models/data/driver';
import { Manager } from '@models/data/manager';
import { BusinessStation } from '@models/data/business-station';
import { DetectionEvent } from '@models/data/detection-event';
import { Tag } from '@models/data/tag';
import { Asset } from '@models/data/asset';
import { Site } from '@models/data/site';
import { Flow } from '@models/data/flow';
import { Infrastructure } from '@models/data/infrastructure';
import { ServiceHospitalier } from '@models/data/service-hospitalier';
import { Commande } from '@models/data/commande';
import { Emplacement } from '@models/data/emplacement';
import { Produit } from '@models/data/produit';
import { LigneCommande } from '@models/data/ligne-commande';
import { StatutCommande } from '@models/data/statut-commande';
import { Category } from '@models/data/category';
import { Permission } from '@models/data/permission';
import { Operations } from '@models/data/operations';
import { Organization } from '@models/data/organization';
import { User } from '@models/data/user';
import { OrganizationEntity } from '@models/data/organization-entity';
import { Role } from '@models/data/role';
import { UsersGroup } from '@models/data/users-group';
import { ObjectReference } from '@models/data/object-reference';
import { ShelfLabel } from '@models/data/shelf-label';
import { OriginType } from '@models/data/origin-type';
import { EventType } from '@models/data/event-type';
import { Log } from '@models/data/log';
import { ShelfLabelEvent } from '@models/data/shelf-label-event';
import { ScheduledTaskType } from '@models/data/scheduled-task-type';
import { ScheduledTask } from '@models/data/scheduled-task';
import { Alerte } from '@models/data/alerte';
import { TypeAlerte } from '@models/data/type-alerte';
import { DevicePairing } from '@models/data/device-pairing';
import { DestinationEmailType } from '@models/data/destination-email-type';
import { DestinationEmail } from '@models/data/destination-email';
import { ScanPointRejectionLog } from '@models/data/scan-point-rejection-log';
import { StationEvent } from '@models/data/station-event';

const TYPES = {
	implementation : Implementation,
	station : Station,
	reachableSchema : ReachableSchema,
	driver : Driver,
	manager : Manager,
	businessStation : BusinessStation,
	detectionEvent : DetectionEvent,
	tag : Tag,
	asset : Asset,
	site : Site,
	flow : Flow,
	infrastructure : Infrastructure,
	serviceHospitalier : ServiceHospitalier,
	commande : Commande,
	emplacement : Emplacement,
	produit : Produit,
	ligneCommande : LigneCommande,
	statutCommande : StatutCommande,
	category : Category,
	permission : Permission,
	operations : Operations,
	organization : Organization,
	user : User,
	organizationEntity : OrganizationEntity,
	role : Role,
	usersGroup : UsersGroup,
	objectReference : ObjectReference,
	shelfLabel : ShelfLabel,
	originType : OriginType,
	eventType : EventType,
	log : Log,
	shelfLabelEvent : ShelfLabelEvent,
	scheduledTaskType : ScheduledTaskType,
	scheduledTask : ScheduledTask,
	alerte : Alerte,
	typeAlerte : TypeAlerte,
	devicePairing : DevicePairing,
	destinationEmailType : DestinationEmailType,
	destinationEmail : DestinationEmail,
	scanPointRejectionLog : ScanPointRejectionLog,
	stationEvent : StationEvent,
} as const;

const getType = (key: string) => {
    if (key in TYPES) return (TYPES as Record<string, new (data: any) => any>)[key];
    throw new Error(`Missing ${key} inside type-resolver.ts`)
}

export { TYPES, getType };
