import { Model } from '@models/data/model';
import { TypeAlerte } from '@models/data/type-alerte';
import { ADAPTERS } from '@models/adapters/adapter-resolver';
import { DateModel } from '@models/adapters/date-adapter';
import { tryInstantiate } from '@shared/utils/object.utils';

export class Alerte extends Model {
	aquite: boolean;
	date: DateModel;
	description: string;
	type: TypeAlerte;

	constructor(data:any) {
		super(data);
		this.aquite = data.aquite;
		this.date = ADAPTERS['DateModel'].parseIfPresent(data.date) as DateModel;
		this.description = data.description;
		this.type = tryInstantiate(data.type);
	}
}