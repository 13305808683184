import { Model } from '@models/data/model';

export class ReferenceInput {
  id: string;

  constructor(id: string | null) {
    if (!id) throw new Error(`'id' couldn't be null or undefined`);
    this.id = id;
  }

  static createIfValid(objModel?: Model): ReferenceInput | undefined {
    return objModel ? new ReferenceInput(objModel.id) : objModel == null ? objModel : undefined;
  }

  static createAllIfValid(objModels?: Model[]): ReferenceInput[] | undefined {
    return objModels
      ? objModels?.map((item) => new ReferenceInput(item.id))
      : undefined;
  }
}
