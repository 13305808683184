import { DynamicSort, Sort } from './sort';

export enum SortNull {
  FIRST = 'FIRST',
  LAST = 'LAST',
}

export type DynamicSortInput = {
  nulls?: SortNull;
  sort: DynamicSort;
};

export class SortInput {
  sort: Sort;
  nulls: SortNull;

  constructor({ sort, nulls = SortNull.LAST }: DynamicSortInput) {
    this.sort = new Sort(sort);
    this.nulls = nulls;
  }
}
