import { ShelfLabelEvent } from '@models/data/shelf-label-event';
import { ADAPTERS } from '@models/adapters/adapter-resolver';
import { ReferenceInput } from '@models/inputs/reference-input';

export class ShelfLabelEventInput {
	data?: any;
	detectionTime?: string;
	shelfLabel?: ReferenceInput;

	constructor(shelfLabelEvent: ShelfLabelEvent) {
		this.data = shelfLabelEvent.data;
		this.detectionTime = ADAPTERS['DateModel'].serializeIfPresent(shelfLabelEvent.detectionTime) as string;
		this.shelfLabel = ReferenceInput.createIfValid(shelfLabelEvent.shelfLabel);
	}
}