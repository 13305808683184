import { Driver } from '@models/data/driver';
import { ReferenceInput } from '@models/inputs/reference-input';

export class DriverInput {
	artifactId?: string;
	groupId?: string;
	possedeImplementations?: ReferenceInput[];
	version?: string;

	constructor(driver: Driver) {
		this.artifactId = driver.artifactId
		this.groupId = driver.groupId
		this.possedeImplementations = ReferenceInput.createAllIfValid(driver.possedeImplementations);
		this.version = driver.version
	}
}