import { Implementation } from '@models/data/implementation';
import { Model } from '@models/data/model';
import { tryInstantiate } from '@shared/utils/object.utils';

export class ReachableSchema extends Model {
	ids: any;
	name: string;
	possedeImplementation: Implementation;
	schema: string;
	type: string;

	constructor(data:any) {
		super(data);
		this.ids = data.ids;
		this.name = data.name;
		this.possedeImplementation = tryInstantiate(data.possedeImplementation);
		this.schema = data.schema;
		this.type = data.type;
	}
}