import { ScanPointRejectionLog } from '@models/data/scan-point-rejection-log';
import { ADAPTERS } from '@models/adapters/adapter-resolver';

export class ScanPointRejectionLogInput {
	date?: string;
	dropPoint?: string;
	errorMessage?: string;
	extracted?: boolean;
	lastScanPointRequestId?: string;
	productCode?: string;
	productName?: string;
	productOrigin?: string;
	quantity?: number;

	constructor(scanPointRejectionLog: ScanPointRejectionLog) {
		this.date = ADAPTERS['DateModel'].serializeIfPresent(scanPointRejectionLog.date) as string;
		this.dropPoint = scanPointRejectionLog.dropPoint
		this.errorMessage = scanPointRejectionLog.errorMessage
		this.extracted = scanPointRejectionLog.extracted
		this.lastScanPointRequestId = scanPointRejectionLog.lastScanPointRequestId
		this.productCode = scanPointRejectionLog.productCode
		this.productName = scanPointRejectionLog.productName
		this.productOrigin = scanPointRejectionLog.productOrigin
		this.quantity = scanPointRejectionLog.quantity
	}
}