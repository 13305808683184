import { ScheduledTask } from '@models/data/scheduled-task';
import { ReferenceInput } from '@models/inputs/reference-input';

export class ScheduledTaskInput {
	cronExpression?: string;
	profile?: string;
	scheduledTaskType?: ReferenceInput;
	timeZone?: string;

	constructor(scheduledTask: ScheduledTask) {
		this.cronExpression = scheduledTask.cronExpression
		this.profile = scheduledTask.profile
		this.scheduledTaskType = ReferenceInput.createIfValid(scheduledTask.scheduledTaskType);
		this.timeZone = scheduledTask.timeZone
	}
}