import { EventType } from '@models/data/event-type';
import { Model } from '@models/data/model';
import { OriginType } from '@models/data/origin-type';
import { ServiceHospitalier } from '@models/data/service-hospitalier';
import { ADAPTERS } from '@models/adapters/adapter-resolver';
import { DateModel } from '@models/adapters/date-adapter';
import { tryInstantiate } from '@shared/utils/object.utils';

export class Log extends Model {
	action: string;
	battery: string;
	bin: string;
	categoryCode: string;
	categoryName: string;
	column: string;
	date: DateModel;
	dropPoint: string;
	eventType: EventType;
	originType: OriginType;
	possedeServiceHospitalier: ServiceHospitalier;
	price: number;
	productCode: string;
	productName: string;
	productOrigin: string;
	quantity: number;
	tagId: number;
	username: string;

	constructor(data:any) {
		super(data);
		this.action = data.action;
		this.battery = data.battery;
		this.bin = data.bin;
		this.categoryCode = data.categoryCode;
		this.categoryName = data.categoryName;
		this.column = data.column;
		this.date = ADAPTERS['DateModel'].parseIfPresent(data.date) as DateModel;
		this.dropPoint = data.dropPoint;
		this.eventType = tryInstantiate(data.eventType);
		this.originType = tryInstantiate(data.originType);
		this.possedeServiceHospitalier = tryInstantiate(data.possedeServiceHospitalier);
		this.price = data.price;
		this.productCode = data.productCode;
		this.productName = data.productName;
		this.productOrigin = data.productOrigin;
		this.quantity = data.quantity;
		this.tagId = data.tagId;
		this.username = data.username;
	}
}