import { DestinationEmail } from '@models/data/destination-email';
import { ReferenceInput } from '@models/inputs/reference-input';

export class DestinationEmailInput {
	destinationEmailType?: ReferenceInput;
	email?: string;

	constructor(destinationEmail: DestinationEmail) {
		this.destinationEmailType = ReferenceInput.createIfValid(destinationEmail.destinationEmailType);
		this.email = destinationEmail.email
	}
}