import { User } from '@models/data/user';
import { ReferenceInput } from '@models/inputs/reference-input';

export class UserInput {
	accountExpired?: boolean;
	accountLocked?: boolean;
	appartientOrganization?: ReferenceInput;
	appartientUsersGroups?: ReferenceInput[];
	credentialsExpired?: boolean;
	email?: string;
	enabled?: boolean;
	firstName?: string;
	isFirstConnection?: boolean;
	lastName?: string;
	password?: string;
	possedeRoles?: ReferenceInput[];
	userName?: string;

	constructor(user: User) {
		this.accountExpired = user.accountExpired
		this.accountLocked = user.accountLocked
		this.appartientOrganization = ReferenceInput.createIfValid(user.appartientOrganization);
		this.appartientUsersGroups = ReferenceInput.createAllIfValid(user.appartientUsersGroups);
		this.credentialsExpired = user.credentialsExpired
		this.email = user.email
		this.enabled = user.enabled
		this.firstName = user.firstName
		this.isFirstConnection = user.isFirstConnection
		this.lastName = user.lastName
		this.password = user.password
		this.possedeRoles = ReferenceInput.createAllIfValid(user.possedeRoles);
		this.userName = user.userName
	}
}