import { BusinessStation } from '@models/data/business-station';
import { Commande } from '@models/data/commande';
import { Emplacement } from '@models/data/emplacement';
import { Site } from '@models/data/site';
import { tryInstantiate } from '@shared/utils/object.utils';
import { tryInstantiateAll } from '@shared/utils/object.utils';

export class ServiceHospitalier extends BusinessStation {
	appartientACommandes: Commande[];
	code: string;
	name: string;
	orderFrequency: string;
	orderFriday: boolean;
	orderMonday: boolean;
	orderSaturday: boolean;
	orderSunday: boolean;
	orderThursday: boolean;
	orderTuesday: boolean;
	orderWednesday: boolean;
	possedeEmplacements: Emplacement[];
	site: Site;

	constructor(data:any) {
		super(data);
		this.appartientACommandes = tryInstantiateAll(data.appartientACommandes)
		this.code = data.code;
		this.name = data.name;
		this.orderFrequency = data.orderFrequency;
		this.orderFriday = data.orderFriday;
		this.orderMonday = data.orderMonday;
		this.orderSaturday = data.orderSaturday;
		this.orderSunday = data.orderSunday;
		this.orderThursday = data.orderThursday;
		this.orderTuesday = data.orderTuesday;
		this.orderWednesday = data.orderWednesday;
		this.possedeEmplacements = tryInstantiateAll(data.possedeEmplacements)
		this.site = tryInstantiate(data.site);
	}
}