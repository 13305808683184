import { Injectable } from '@angular/core';
import { DocumentNode, FetchResult, gql } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { catchError, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginationResult } from '@shared/graphql/pagination-result';
import { QuerySetting } from '@shared/graphql/query-setting';
import { DynamicSortInput, SortInput } from '@shared/graphql/sort-input';
import { Model } from '@models/data/model';
import { Filter } from '@shared/graphql/filter';
import * as Inputs from '@models/inputs/inputs';
import { Pagination } from '@shared/graphql/pagination';
import { resolve } from '@models/resolver';

@Injectable({
    providedIn: 'root',
})
export class GraphqlService {
    constructor(private apollo: Apollo) {
    }

    // https://stackoverflow.com/a/2970667/13216391
    // Can be replaced by lodash if used elsewhere.
    camelize(str: string): string {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        });
    }

    create<T extends Model>(
        object: T,
        definition: string,
    ): Observable<FetchResult<T>> {
        const mutationName = `create${definition}`;
        const inputType = `${definition}Input`;
        const mutation = this.buildMutation(mutationName, inputType);
        const inputInstance = this.getInstance(inputType, object);
        return this.mutate(mutation, {
            input: inputInstance,
        });
    }

    update<T extends Model>(
        object: T,
        definition: string,
    ): Observable<FetchResult<T>> {
        const mutationName = `update${definition}`;
        const inputType = `${definition}Input`;
        const mutation = this.buildUpdateMutation(mutationName, inputType);
        const inputInstance = this.getInstance(inputType, object);
        return this.mutate(mutation, {
            id: object.id,
            input: inputInstance,
        });
    }

    delete<T extends Model>(
        object: T,
        definition: string,
    ): Observable<FetchResult<T>> {
        const mutationName = `delete${definition}`;
        const mutation = this.buildIdMutation(mutationName);
        return this.mutateWithId(mutation, object.id);
    }

    public findById<T extends Model>(
        id: string,
        definition: string,
        fields: string[],
    ): Observable<T> {
        const queryName = `${definition}ById`;
        const requiredFields: string = this.buildRequiredFields(
            fields.map((field) => field.replace('[].', '.')),
        );
        const query = `{${this.camelize(
            queryName,
        )}(id: ${id}){id, __typename, ${requiredFields}}}`;
        return this.apollo
            .query<{ [queryName: string]: T }>({
                query: gql`
          ${query}
        `,
                fetchPolicy: 'no-cache',
            })
            .pipe(
                map(({data}) => {
                    const camelizedQueryName: string = this.camelize(queryName);
                    const camelizedDefinition = this.camelize(definition);
                    return resolve<T>(camelizedDefinition, data[camelizedQueryName]);
                }),
                catchError((err, a) => {
                    throw new Error(err.message);
                })
            );
    }

    public findAllWithFilters<T extends Model>(
        definition: string,
        attributes: string[],
        filter: Filter | null = null,
        pagination: Pagination | null = null,
        sort: SortInput | DynamicSortInput | null = null,
    ): Observable<PaginationResult<T>> {
        const queryName = `${this.camelize(definition)}s`;
        const query: string = this.buildQueryFilter(
            queryName,
            attributes.map((attr) => attr.replace('[].', '.')),
        );
        if (pagination !== null && attributes.includes('name') && sort === null) {
            sort = new SortInput({
                sort: {
                    attribute: 'name',
                    direction: 'ASC',
                },
            });
        }
        return this.apollo
            .query<{ [queryName: string]: PaginationResult<T> }>({
                query: gql(query),
                fetchPolicy: 'no-cache',
                variables: {
                    querySetting: new QuerySetting({
                        filtering: filter,
                        paging: pagination,
                        sorting: sort,
                    }),
                },
            })
            .pipe(
                map(({data}) => {
                    return data[queryName];
                }),
                map(({items, totalCount}) => {
                    const camelizedDefinition = this.camelize(definition);
                    const itemsInstanced: T[] = items.map((item: T) => {
                        let definition;
                        if (item['__typename']) {
                            definition = this.camelize(item['__typename']);
                        } else {
                            definition = camelizedDefinition;
                        }
                        return resolve(definition, item);
                    });
                    return {items: itemsInstanced, totalCount};
                }),
                catchError((err, a) => {
                    throw new Error(err.message);
                })
            );
    }

    private getInstance(inputType: string, args: any) {
        // @ts-expect-error: Inputs need to have string keys
        const inputPrototype = Inputs[inputType];
        return new inputPrototype(args);
    }

    private buildUpdateMutation(name: string, inputType: string): DocumentNode {
        const mutationQuery = `mutation ${name}($id: ID!, $input: ${inputType}!) {
      ${name}(id: $id, input: $input) {
        id
      }
    }`;
        return gql(mutationQuery);
    }

    // TODO: input name in mutations
    private buildMutation(name: string, inputType: string): DocumentNode {
        const mutationQuery = `mutation ${name}($input: ${inputType}!) {
      ${name}(input: $input) {
        id
      }
    }`;
        return gql(mutationQuery);
    }

    private mutate<T extends Model>(
        mutation: DocumentNode,
        input: any,
    ): Observable<FetchResult<T>> {
        return this.apollo.mutate({
            mutation: mutation,
            variables: input,
        });
    }

    private buildIdMutation(name: string): DocumentNode {
        const mutationQuery = `mutation ${name}($input: ID!) {
      ${name}(id: $input) {
        id
      }
    }`;
        return gql(mutationQuery);
    }

    private mutateWithId<T extends Model>(
        mutation: DocumentNode,
        objectId: any,
    ): Observable<FetchResult<T>> {
        return this.apollo.mutate({
            mutation: mutation,
            variables: {
                input: objectId,
            },
        });
    }

    private buildQueryFilter(queryName: string, attributes: string[]) {
        const requiredFields: string = this.buildRequiredFields(attributes);
        return `
    query paginationQuery($querySetting: QuerySettingInput!){
      ${queryName}(querySetting: $querySetting) {
        totalCount
        items {
          id
          ${requiredFields}
          __typename
        }
      }
    }`;
    }

    private buildRequiredFields(attributes: string[]): string {
        const nestedFieldInheritancedRegex = /^[\w\d]+\([\w\d]+\).+$/g;
        const nestedAttributes: Map<string, string[]> = new Map();
        const inheritanceAttributes: Map<string, Map<string, string[]>> = new Map();
        const simpleAttributes: string[] = [];
        let requiredFields = '';

        attributes
            .filter((attr) => attr !== 'id' && !attr.startsWith('?'))
            .forEach((attribute, index) => {
                if (nestedFieldInheritancedRegex.test(attribute)) {
                    const nestedFieldNameRegex = /^[\w\d]+/g;
                    const inheritanceClassRegex = /\([\w\d]+\)/g;
                    const attributesRegex = /\{[\w\d,\s]+\}/g;

                    const nestedFieldNameResult = nestedFieldNameRegex.exec(attribute);
                    const inheritanceClassResult = inheritanceClassRegex.exec(attribute);
                    const attributesResult = attributesRegex.exec(attribute);

                    if (
                        !nestedFieldNameResult ||
                        !inheritanceClassResult ||
                        !attributesResult
                    ) {
                        return;
                    }
                    let nestedFieldName = nestedFieldNameResult[0];
                    const inheritanceClass = inheritanceClassResult[0].slice(1, -1);
                    const attributes: string[] = attributesResult[0]
                        .slice(1, -1)
                        .replaceAll(' ', '')
                        .split(',');

                    if (nestedFieldName === 'this') {
                        nestedFieldName = '';
                    }

                    const inheritanceAttributeForNestedField: Map<string, string[]> =
                        inheritanceAttributes.get(nestedFieldName) ?? new Map();
                    if (!inheritanceAttributes.has(nestedFieldName)) {
                        inheritanceAttributes.set(
                            nestedFieldName,
                            inheritanceAttributeForNestedField,
                        );
                    }
                    inheritanceAttributeForNestedField.set(inheritanceClass, [
                        ...new Set([
                            ...(inheritanceAttributeForNestedField.get(inheritanceClass) ??
                                []),
                            ...attributes,
                        ]),
                    ]);
                    return;
                }
                if (attribute.indexOf('.') > 0) {
                    const levels = attribute.split('.');
                    const subLevel = [...levels];
                    subLevel.shift();
                    if (levels.length > 2) {
                        if (nestedAttributes.has(levels[0])) {
                            const s = `${nestedAttributes.get(
                                levels[0],
                            )}\n${this.buildRequiredFields([subLevel.join('.')])}`;
                            nestedAttributes.set(levels[0], [s]);
                        } else {
                            nestedAttributes.set(levels[0], [
                                this.buildRequiredFields([subLevel.join('.')]),
                            ]);
                        }
                    } else {
                        const splitAttributes: string[] = attribute.split('.');
                        if (!nestedAttributes.get(splitAttributes[0])) {
                            nestedAttributes.set(splitAttributes[0], [splitAttributes[1]]);
                        } else {
                            nestedAttributes
                                .get(splitAttributes[0])
                                ?.push(splitAttributes[1]);
                        }
                    }
                } else {
                    simpleAttributes.push(attribute);
                }
            });
        requiredFields += simpleAttributes
            .map((attr) => attr.replaceAll('}', ', __typename}'))
            .join('\n');
        nestedAttributes.forEach((value: string[], key: string) => {
            if (simpleAttributes.length > 0) {
                requiredFields += '\n';
            }
            let inheritanceFields = '';
            const inheritanceAttributesForKey = inheritanceAttributes.get(key);
            if (inheritanceAttributesForKey) {
                inheritanceAttributesForKey.forEach((attributes, impl) => {
                    inheritanceFields += `
            ... on ${impl} {
            ${attributes.join('\n')}
          }

          `;
                });
            }
            const nestedField = `${key}{${value.join(
                '\n',
            )}${inheritanceFields}\n__typename}`;
            requiredFields += nestedField;
        });

        const inheritanceAttributesForMainObject = inheritanceAttributes.get('');
        if (inheritanceAttributesForMainObject) {
            inheritanceAttributesForMainObject.forEach((attributes, impl) => {
                requiredFields += `
            ... on ${impl} {
            ${attributes.join('\n')}
          }

          `;
            });
        }

        return requiredFields;
    }
}
