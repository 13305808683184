import { Model } from '@models/data/model';
import { Tag } from '@models/data/tag';
import { tryInstantiateAll } from '@shared/utils/object.utils';

export class Asset extends Model {
	manageTags: Tag[];

	constructor(data:any) {
		super(data);
		this.manageTags = tryInstantiateAll(data.manageTags)
	}
}