import { LigneCommande } from '@models/data/ligne-commande';
import { ReferenceInput } from '@models/inputs/reference-input';

export class LigneCommandeInput {
	code?: string;
	codeProduct?: string;
	emplacement?: ReferenceInput;
	possedeCommande?: ReferenceInput;
	qty?: number;
	statut?: ReferenceInput;

	constructor(ligneCommande: LigneCommande) {
		this.code = ligneCommande.code
		this.codeProduct = ligneCommande.codeProduct
		this.emplacement = ReferenceInput.createIfValid(ligneCommande.emplacement);
		this.possedeCommande = ReferenceInput.createIfValid(ligneCommande.possedeCommande);
		this.qty = ligneCommande.qty
		this.statut = ReferenceInput.createIfValid(ligneCommande.statut);
	}
}