import { Model } from '@models/data/model';
import { User } from '@models/data/user';
import { tryInstantiateAll } from '@shared/utils/object.utils';

export class UsersGroup extends Model {
	appartientUsers: User[];
	name: string;

	constructor(data:any) {
		super(data);
		this.appartientUsers = tryInstantiateAll(data.appartientUsers)
		this.name = data.name;
	}
}