import { DetectionEvent } from '@models/data/detection-event';
import { ADAPTERS } from '@models/adapters/adapter-resolver';
import { ReferenceInput } from '@models/inputs/reference-input';

export class DetectionEventInput {
	detectionTime?: string;
	lieeStation?: ReferenceInput;
	lieeTag?: ReferenceInput;

	constructor(detectionEvent: DetectionEvent) {
		this.detectionTime = ADAPTERS['DateModel'].serializeIfPresent(detectionEvent.detectionTime) as string;
		this.lieeStation = ReferenceInput.createIfValid(detectionEvent.lieeStation);
		this.lieeTag = ReferenceInput.createIfValid(detectionEvent.lieeTag);
	}
}