import { Station } from '@models/data/station';
import { ReferenceInput } from '@models/inputs/reference-input';

export class StationInput {
	configuration?: string;
	description?: string;
	lieeBusinessStation?: ReferenceInput;
	lieeDetectionEvents?: ReferenceInput[];
	manageManager?: ReferenceInput;
	name?: string;
	possedeImplementation?: ReferenceInput;
	workflow?: string;

	constructor(station: Station) {
		this.configuration = station.configuration
		this.description = station.description
		this.lieeBusinessStation = ReferenceInput.createIfValid(station.lieeBusinessStation);
		this.lieeDetectionEvents = ReferenceInput.createAllIfValid(station.lieeDetectionEvents);
		this.manageManager = ReferenceInput.createIfValid(station.manageManager);
		this.name = station.name
		this.possedeImplementation = ReferenceInput.createIfValid(station.possedeImplementation);
		this.workflow = station.workflow
	}
}