import { Model } from '@models/data/model';
import { ADAPTERS } from '@models/adapters/adapter-resolver';
import { DateModel } from '@models/adapters/date-adapter';

export class ScanPointRejectionLog extends Model {
	date: DateModel;
	dropPoint: string;
	errorMessage: string;
	extracted: boolean;
	lastScanPointRequestId: string;
	productCode: string;
	productName: string;
	productOrigin: string;
	quantity: number;

	constructor(data:any) {
		super(data);
		this.date = ADAPTERS['DateModel'].parseIfPresent(data.date) as DateModel;
		this.dropPoint = data.dropPoint;
		this.errorMessage = data.errorMessage;
		this.extracted = data.extracted;
		this.lastScanPointRequestId = data.lastScanPointRequestId;
		this.productCode = data.productCode;
		this.productName = data.productName;
		this.productOrigin = data.productOrigin;
		this.quantity = data.quantity;
	}
}