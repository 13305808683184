import { DevicePairing } from '@models/data/device-pairing';
import { ReferenceInput } from '@models/inputs/reference-input';

export class DevicePairingInput {
	businessStation?: ReferenceInput;
	deviceId?: string;
	station?: ReferenceInput;

	constructor(devicePairing: DevicePairing) {
		this.businessStation = ReferenceInput.createIfValid(devicePairing.businessStation);
		this.deviceId = devicePairing.deviceId
		this.station = ReferenceInput.createIfValid(devicePairing.station);
	}
}