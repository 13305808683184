import { TypeAlerte } from '@models/data/type-alerte';
import { ReferenceInput } from '@models/inputs/reference-input';

export class TypeAlerteInput {
	alertes?: ReferenceInput[];
	nom?: string;

	constructor(typeAlerte: TypeAlerte) {
		this.alertes = ReferenceInput.createAllIfValid(typeAlerte.alertes);
		this.nom = typeAlerte.nom
	}
}