import { Emplacement } from '@models/data/emplacement';
import { ReferenceInput } from '@models/inputs/reference-input';

export class EmplacementInput {
	barcode?: string;
	bin?: string;
	column?: string;
	erequisition?: boolean;
	info1?: string;
	info2?: string;
	info3?: string;
	info4?: string;
	info5?: string;
	isHidden?: boolean;
	lastReadOrigin?: string;
	lastScanPointRequestId?: string;
	linkedToCard?: boolean;
	maxQty?: number;
	minQty?: number;
	orderQty?: number;
	orderQtyMultiplier?: number;
	possedeLigneCommande?: ReferenceInput;
	possedeProduit?: ReferenceInput;
	possedeServiceHospitalier?: ReferenceInput;
	requestReplenishment?: boolean;
	subdivision?: string;

	constructor(emplacement: Emplacement) {
		this.barcode = emplacement.barcode
		this.bin = emplacement.bin
		this.column = emplacement.column
		this.erequisition = emplacement.erequisition
		this.info1 = emplacement.info1
		this.info2 = emplacement.info2
		this.info3 = emplacement.info3
		this.info4 = emplacement.info4
		this.info5 = emplacement.info5
		this.isHidden = emplacement.isHidden
		this.lastReadOrigin = emplacement.lastReadOrigin
		this.lastScanPointRequestId = emplacement.lastScanPointRequestId
		this.linkedToCard = emplacement.linkedToCard
		this.maxQty = emplacement.maxQty
		this.minQty = emplacement.minQty
		this.orderQty = emplacement.orderQty
		this.orderQtyMultiplier = emplacement.orderQtyMultiplier
		this.possedeLigneCommande = ReferenceInput.createIfValid(emplacement.possedeLigneCommande);
		this.possedeProduit = ReferenceInput.createIfValid(emplacement.possedeProduit);
		this.possedeServiceHospitalier = ReferenceInput.createIfValid(emplacement.possedeServiceHospitalier);
		this.requestReplenishment = emplacement.requestReplenishment
		this.subdivision = emplacement.subdivision
	}
}