import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { from } from 'rxjs';

type ToastType = 'success' | 'info' | 'warning' | 'error';

const SNACKBAR_CSS_CLASS  = 'snackbar-custom';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  static DEFAULT_DURATION = 5000;

  private readonly _snackBar = inject(MatSnackBar);
  private readonly translateService = inject(TranslateService);

  success(
    message: string,
    duration: number | null = ToastService.DEFAULT_DURATION,
  ) {
    if (duration) {
      this.openDelayToast(message, 'success', duration);
    } else {
      this.openToast(message, 'success');
    }
  }

  info(
    message: string,
    duration: number | null = ToastService.DEFAULT_DURATION,
  ) {
    if (duration) {
      this.openDelayToast(message, 'info', duration);
    } else {
      this.openToast(message, 'info');
    }
  }

  warning(
    message: string,
    duration: number | null = ToastService.DEFAULT_DURATION,
  ) {
    if (duration) {
      this.openDelayToast(message, 'warning', duration);
    } else {
      this.openToast(message, 'warning');
    }
  }

  error(
    message: string,
    duration: number | null = ToastService.DEFAULT_DURATION,
  ) {
    if (duration) {
      this.openDelayToast(message, 'error', duration);
    } else {
      this.openToast(message, 'error');
    }
  }

  private openToast(message: string, type: ToastType) {
    this._snackBar.open(message, 'OK', {
      verticalPosition: 'top',
      panelClass: [`toast-${type}`],
    });
  }

  private openDelayToast(message: string, type: ToastType, duration: number) {
    const translation = this.translateService.instant(message);
    this._snackBar.open(translation, 'OK', {
      duration,
      verticalPosition: 'top',
      panelClass: [SNACKBAR_CSS_CLASS, `toast-${type}`],
    });
  }
}
