import { OriginType } from '@models/data/origin-type';


export class OriginTypeInput {
	code?: string;
	name?: string;

	constructor(originType: OriginType) {
		this.code = originType.code
		this.name = originType.name
	}
}