import { Infrastructure } from '@models/data/infrastructure';
import { Manager } from '@models/data/manager';
import { Model } from '@models/data/model';
import { ServiceHospitalier } from '@models/data/service-hospitalier';
import { tryInstantiate } from '@shared/utils/object.utils';
import { tryInstantiateAll } from '@shared/utils/object.utils';

export class Site extends Model {
	description: string;
	name: string;
	possedeInfrastructure: Infrastructure;
	possedeManagers: Manager[];
	serviceHospitaliers: ServiceHospitalier[];

	constructor(data:any) {
		super(data);
		this.description = data.description;
		this.name = data.name;
		this.possedeInfrastructure = tryInstantiate(data.possedeInfrastructure);
		this.possedeManagers = tryInstantiateAll(data.possedeManagers)
		this.serviceHospitaliers = tryInstantiateAll(data.serviceHospitaliers)
	}
}