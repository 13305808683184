import { Commande } from '@models/data/commande';
import { ADAPTERS } from '@models/adapters/adapter-resolver';
import { ReferenceInput } from '@models/inputs/reference-input';

export class CommandeInput {
	appartientAServiceHospitalier?: ReferenceInput;
	closingDate?: string;
	creationDate?: string;
	numero?: string;
	possedeLigneCommandes?: ReferenceInput[];
	statut?: ReferenceInput;

	constructor(commande: Commande) {
		this.appartientAServiceHospitalier = ReferenceInput.createIfValid(commande.appartientAServiceHospitalier);
		this.closingDate = ADAPTERS['DateModel'].serializeIfPresent(commande.closingDate) as string;
		this.creationDate = ADAPTERS['DateModel'].serializeIfPresent(commande.creationDate) as string;
		this.numero = commande.numero
		this.possedeLigneCommandes = ReferenceInput.createAllIfValid(commande.possedeLigneCommandes);
		this.statut = ReferenceInput.createIfValid(commande.statut);
	}
}