import { Asset } from '@models/data/asset';
import { Model } from '@models/data/model';
import { ADAPTERS } from '@models/adapters/adapter-resolver';
import { DateModel } from '@models/adapters/date-adapter';
import { tryInstantiate } from '@shared/utils/object.utils';

export class Tag extends Model {
	lastDeviceId: string;
	manageAsset: Asset;
	pairingStartTime: DateModel;

	constructor(data:any) {
		super(data);
		this.lastDeviceId = data.lastDeviceId;
		this.manageAsset = tryInstantiate(data.manageAsset);
		this.pairingStartTime = ADAPTERS['DateModel'].parseIfPresent(data.pairingStartTime) as DateModel;
	}
}