import { DestinationEmailType } from '@models/data/destination-email-type';


export class DestinationEmailTypeInput {
	code?: string;
	name?: string;

	constructor(destinationEmailType: DestinationEmailType) {
		this.code = destinationEmailType.code
		this.name = destinationEmailType.name
	}
}