import { Commande } from '@models/data/commande';
import { Emplacement } from '@models/data/emplacement';
import { Model } from '@models/data/model';
import { StatutCommande } from '@models/data/statut-commande';
import { tryInstantiate } from '@shared/utils/object.utils';

export class LigneCommande extends Model {
	code: string;
	codeProduct: string;
	emplacement: Emplacement;
	possedeCommande: Commande;
	qty: number;
	statut: StatutCommande;

	constructor(data:any) {
		super(data);
		this.code = data.code;
		this.codeProduct = data.codeProduct;
		this.emplacement = tryInstantiate(data.emplacement);
		this.possedeCommande = tryInstantiate(data.possedeCommande);
		this.qty = data.qty;
		this.statut = tryInstantiate(data.statut);
	}
}