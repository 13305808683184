import { ADAPTERS } from '@models/adapters/adapter-resolver';
import { DateModel } from '@models/adapters/date-adapter';
import { Tag } from '@models/data/tag';


export class ShelfLabel extends Tag {
	action: string;
	battery: string;
	firmware: string;
	imageId: string;
	macAddress: string;
	rssi: number;
	screen: number;
	lastDetectionTime: DateModel;
	key: string;

	constructor(data:any) {
		super(data);
		this.action = data.action;
		this.battery = data.battery;
		this.firmware = data.firmware;
		this.imageId = data.imageId;
		this.macAddress = data.macAddress;
		this.rssi = data.rssi;
		this.screen = data.screen;
		this.lastDetectionTime = ADAPTERS['DateModel'].parseIfPresent(data.lastDetectionTime) as DateModel;
		this.key = data.key;
	}
}