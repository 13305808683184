import { Model } from '@models/data/model';
import { ShelfLabel } from '@models/data/shelf-label';
import { ADAPTERS } from '@models/adapters/adapter-resolver';
import { DateModel } from '@models/adapters/date-adapter';
import { tryInstantiate } from '@shared/utils/object.utils';

export class ShelfLabelEvent extends Model {
	data: any;
	detectionTime: DateModel;
	shelfLabel: ShelfLabel;
	eventType: string;

	constructor(data:any) {
		super(data);
		this.data = data.data;
		this.eventType = data.eventType;
		this.detectionTime = ADAPTERS['DateModel'].parseIfPresent(data.detectionTime) as DateModel;
		this.shelfLabel = tryInstantiate(data.shelfLabel);
	}
}