import { Asset } from '@models/data/asset';
import { LigneCommande } from '@models/data/ligne-commande';
import { Produit } from '@models/data/produit';
import { ServiceHospitalier } from '@models/data/service-hospitalier';
import { tryInstantiate } from '@shared/utils/object.utils';

export class Emplacement extends Asset {
	barcode: string;
	bin: string;
	column: string;
	erequisition: boolean;
	info1: string;
	info2: string;
	info3: string;
	info4: string;
	info5: string;
	isHidden: boolean;
	lastReadOrigin: string;
	lastScanPointRequestId: string;
	linkedToCard: boolean;
	maxQty: number;
	minQty: number;
	orderQty: number;
	orderQtyMultiplier: number;
	possedeLigneCommande: LigneCommande;
	possedeProduit: Produit;
	possedeServiceHospitalier: ServiceHospitalier;
	requestReplenishment: boolean;
	subdivision: string;

	constructor(data:any) {
		super(data);
		this.barcode = data.barcode;
		this.bin = data.bin;
		this.column = data.column;
		this.erequisition = data.erequisition;
		this.info1 = data.info1;
		this.info2 = data.info2;
		this.info3 = data.info3;
		this.info4 = data.info4;
		this.info5 = data.info5;
		this.isHidden = data.isHidden;
		this.lastReadOrigin = data.lastReadOrigin;
		this.lastScanPointRequestId = data.lastScanPointRequestId;
		this.linkedToCard = data.linkedToCard;
		this.maxQty = data.maxQty;
		this.minQty = data.minQty;
		this.orderQty = data.orderQty;
		this.orderQtyMultiplier = data.orderQtyMultiplier;
		this.possedeLigneCommande = tryInstantiate(data.possedeLigneCommande);
		this.possedeProduit = tryInstantiate(data.possedeProduit);
		this.possedeServiceHospitalier = tryInstantiate(data.possedeServiceHospitalier);
		this.requestReplenishment = data.requestReplenishment;
		this.subdivision = data.subdivision;
	}
}