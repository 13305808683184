import { Pagination } from './pagination';
import { DynamicSortInput, SortInput } from './sort-input';
import { Filter } from '@shared/graphql/filter';

type QuerySettingParameter = {
  filtering?: Filter | null;
  sorting?: SortInput | DynamicSortInput | null;
  paging?: Pagination | null;
};

export class QuerySetting {
  filtering: Filter | null;
  sorting: SortInput | DynamicSortInput | null;
  paging: Pagination | null;

  constructor({
    filtering = null,
    sorting = null,
    paging = null,
  }: QuerySettingParameter) {
    this.filtering = filtering;
    this.sorting = sorting;
    this.paging = paging;
  }
}
