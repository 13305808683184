export type DynamicSort = {
    attribute: string;
    direction: 'ASC' | 'DESC';
    then?: DynamicSort | null;
};

export class Sort {
    attribute: string;
    direction: 'ASC' | 'DESC';
    then: Sort | null;

    constructor(data?: DynamicSort) {
        if (data?.attribute === undefined || data.direction === undefined) {
            throw new Error("Missing attribute on sort");
        }

        this.attribute = data.attribute;
        this.direction = data.direction;
        if (data?.then) {
            this.then = new Sort(data.then);
        } else {
            this.then = null;
        }
    }

    static generate(dynamicSort: DynamicSort): Sort {
        if (dynamicSort?.attribute === undefined || dynamicSort.direction === undefined) {
            throw new Error("Missing attribute on sort generate method");
        }

        const sort = new Sort();
        sort.attribute = dynamicSort.attribute;
        sort.direction = dynamicSort.direction;
        if (dynamicSort.then) {
            sort.then = Sort.generate(dynamicSort.then);
        } else {
            sort.then = null;
        }
        return sort;
    }
}
