import { Flow } from '@models/data/flow';
import { Model } from '@models/data/model';
import { Site } from '@models/data/site';
import { Station } from '@models/data/station';
import { tryInstantiate } from '@shared/utils/object.utils';
import { tryInstantiateAll } from '@shared/utils/object.utils';

export class Manager extends Model {
	description: string;
	manageFlow: Flow;
	manageStations: Station[];
	name: string;
	possedeSite: Site;

	constructor(data:any) {
		super(data);
		this.description = data.description;
		this.manageFlow = tryInstantiate(data.manageFlow);
		this.manageStations = tryInstantiateAll(data.manageStations)
		this.name = data.name;
		this.possedeSite = tryInstantiate(data.possedeSite);
	}
}