import { Role } from '@models/data/role';
import { ReferenceInput } from '@models/inputs/reference-input';

export class RoleInput {
	name?: string;
	permissions?: any;
	possedeUsers?: ReferenceInput[];

	constructor(role: Role) {
		this.name = role.name
		this.permissions = role.permissions
		this.possedeUsers = ReferenceInput.createAllIfValid(role.possedeUsers);
	}
}