import { Produit } from '@models/data/produit';
import { ADAPTERS } from '@models/adapters/adapter-resolver';
import { ReferenceInput } from '@models/inputs/reference-input';

export class ProduitInput {
	barcode?: string;
	category?: ReferenceInput;
	code2?: string;
	code3?: string;
	code?: string;
	lastUpdate?: string;
	name2?: string;
	name3?: string;
	name?: string;
	origin?: string;
	packagingQty?: number;
	price?: number;
	unit?: string;
	vaT?: number;
	validFormat?: boolean;

	constructor(produit: Produit) {
		this.barcode = produit.barcode
		this.category = ReferenceInput.createIfValid(produit.category);
		this.code = produit.code
		this.code2 = produit.code2
		this.code3 = produit.code3
		this.lastUpdate = ADAPTERS['DateModel'].serializeIfPresent(produit.lastUpdate) as string;
		this.name = produit.name
		this.name2 = produit.name2
		this.name3 = produit.name3
		this.origin = produit.origin
		this.packagingQty = produit.packagingQty
		this.price = produit.price
		this.unit = produit.unit
		this.vaT = produit.vaT
		this.validFormat = produit.validFormat
	}
}